export default defineNuxtRouteMiddleware(async () => {
  const { profile } = useProfileStore();
  const { scienceAreas } = useScienceAreaStore();

  if (!scienceAreas.value?.fieldOfScience?.length) {
    const { getScienceAreas } = useScienceAreaStore();

    await getScienceAreas();
  }

  if (!profile.value?.cn) {
    const { getProfile } = useProfileStore();

    await getProfile();
  }
});
